var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-12" },
    [
      _c(
        "b-button",
        {
          attrs: {
            size: "sm",
            variant: "outline-primary",
            "data-test": "btn-select-all",
          },
          on: { click: _vm.selectAllRows },
        },
        [
          _c("i", { staticClass: "fa fa-check-square-o" }),
          _vm._v(" " + _vm._s(_vm.$t("payments.buttons.selectAll")) + " "),
        ]
      ),
      _c(
        "b-button",
        {
          staticClass: "ml-2",
          attrs: {
            size: "sm",
            variant: "outline-danger",
            "data-test": "btn-unselect-all",
          },
          on: { click: _vm.clearSelected },
        },
        [
          _c("i", { staticClass: "fa fa-eraser" }),
          _vm._v(" " + _vm._s(_vm.$t("payments.buttons.unselectAll")) + " "),
        ]
      ),
      _c("b-table", {
        ref: "selectableTable",
        attrs: {
          "data-test": "table-selectable",
          selectable: _vm.disableNegotiation,
          "select-mode": "multi",
          items: _vm.listTransactions,
          fields: _vm.fields,
          responsive: "sm",
          "selected-variant": "secondary",
          busy: _vm.loading,
        },
        on: { "row-selected": _vm.onRowSelected },
        scopedSlots: _vm._u(
          [
            _vm.disableNegotiation
              ? {
                  key: "cell(selected)",
                  fn: function ({ rowSelected }) {
                    return [
                      rowSelected
                        ? [
                            _c("i", {
                              staticClass:
                                "fa fa-check-square-o icon-custom-size",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        : [
                            _c("i", {
                              staticClass: "fa fa-square-o icon-custom-size",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ],
                    ]
                  },
                }
              : null,
            {
              key: "cell(kind)",
              fn: function (data) {
                return [
                  _c(
                    "big-badge",
                    { attrs: { variant: _vm.setKindFormat(data.item.kind) } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.setKindLabel(data.item.kind)) + " "
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: "cell(amount)",
              fn: function (data) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.setPriceFormat(
                          _vm.$t("payments.labels.currency"),
                          data.item.amount
                        )
                      ) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "cell(overdue_limit)",
              fn: function (data) {
                return [
                  _c("span", { staticClass: "text-uppercase" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(
                            data.item.overdue_limit,
                            "DD/MM/YYYY"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center text-dark my-2" },
                    [
                      _c("b-spinner", { staticClass: "align-middle" }),
                      _c("strong", [_vm._v("Carregando...")]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }