<template>
  <div class="col-12">
    <b-button
      size="sm"
      variant="outline-primary"
      data-test="btn-select-all"
      @click="selectAllRows"
    >
      <i class="fa fa-check-square-o" />
      {{ $t('payments.buttons.selectAll') }}
    </b-button>
    <b-button
      size="sm"
      variant="outline-danger"
      class="ml-2" 
      data-test="btn-unselect-all"
      @click="clearSelected"
    >
      <i class="fa fa-eraser" />
      {{ $t('payments.buttons.unselectAll') }}
    </b-button>
    <b-table
      ref="selectableTable"
      data-test="table-selectable"
      :selectable="disableNegotiation"
      select-mode="multi"
      :items="listTransactions"
      :fields="fields"
      responsive="sm"
      selected-variant="secondary"
      :busy="loading"
      @row-selected="onRowSelected"
    >
      <template v-if="disableNegotiation" v-slot:cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <i class="fa fa-check-square-o icon-custom-size" aria-hidden="true" />
        </template>
        <template v-else>
          <i class="fa fa-square-o icon-custom-size" aria-hidden="true" />
        </template>
      </template>
      <template v-slot:cell(kind)="data">
        <big-badge :variant="setKindFormat(data.item.kind)">
          {{ setKindLabel(data.item.kind) }}
        </big-badge>
      </template>
      <template v-slot:cell(amount)="data">
        {{ setPriceFormat($t('payments.labels.currency'), data.item.amount) }}
      </template>
      <template v-slot:cell(overdue_limit)="data">
        <span class="text-uppercase">
          {{ data.item.overdue_limit | moment('DD/MM/YYYY') }}
        </span>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner class="align-middle" />
          <strong>Carregando...</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import TRANSACTIONS_NEGOTIABLE from '@graphql/negotiation/queries/negotiable.gql';
import { formatPrice } from '@utils/numbers';
import { formatKind, parseKind } from '@utils/wallet';
import BigBadge from '@components/shared/big-badge';
import { makeQuery } from '@graphql/middleware'
export default {
    name: 'DriverNegotiationTable',
    components: {
      BigBadge
    },
    props: {
      driverId: {
        type: String,
        default: ''
      },
      disableNegotiation: {
        type: Boolean,
        deafult: false
      }
    },
    data () {
      return {
        fields: [
          {key: 'selected', label: ''},
          {key: 'kind', label: this.$t('payments.tables.kind')},
          {key: 'description', label: this.$t('payments.tables.description')},
          {key: 'amount', label: this.$t('payments.tables.amount')},
          {key: 'overdue_limit', label: this.$t('payments.tables.overdue_limit')},
        ],
        selected: [],
        listTransactions: [],
        loading: true
      }
    },
    mounted () {
      this.getTransactionsNegotiated()
    },
    methods: {
      getTransactionsNegotiated () {
        makeQuery(
          TRANSACTIONS_NEGOTIABLE,
          { driver: this.driverId }
        )
        .then((res) => {
          this.loading = false
          this.listTransactions = res.data.getTransactionsNegotiable.items
        })
      },
      onRowSelected(item) {
        this.selected = item;
        this.$emit('selected', item)
      },
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
      setPriceFormat(_currency, _value) {
        return formatPrice(_currency, _value);
      },
      setKindLabel: parseKind,
      setKindFormat: formatKind,
    }
}
</script>

<style scoped>
.icon-custom-size {
  font-size: 18px;
}
</style>